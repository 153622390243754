import { useCallback, useState } from 'react';

import { useSdk } from 'util/sdkContext';

function useCreateSharetribeListing() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [listing, setListing] = useState(null);
  const sdk = useSdk();

  const createListing = useCallback(
    async data => {
      setIsLoading(true);
      setError(null);

      const searchWords = [
        data.title,
        data.publicData?.brandName,
        data.publicData?.productType,
        data.publicData?.productSubtype,
        data.publicData?.category,
        data.publicData?.colors?.join(' '),
      ]
        .filter(Boolean)
        .join(' ');

      if (searchWords) {
        data.description = searchWords;
      }

      const queryParams = {
        expand: true,
        include: ['author', 'currentStock'],
      };

      try {
        const response = await sdk.ownListings.createDraft(data, queryParams);
        setListing(response.data.data);
        return response;
      } catch (e: any) {
        setError(e);
        console.error('create-listing-draft-failed', { listingData: data, error: e });
      } finally {
        setIsLoading(false);
      }
    },
    [sdk]
  );

  return { createListing, isLoading, error, listing };
}

export default useCreateSharetribeListing;
