import { useMutation } from '@tanstack/react-query';

import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';

import { getPresignedUrl } from '../misc/getPresignedUrl';
import { ImageType } from '../misc/getPresignedUrl';
import { useCreateListing } from './useCreateListing';
import { useGetBrandIdOptions } from './useGetBrandIdOptions';
import { useGetProductFilterOptions } from './useGetProductFilterOptions';
import { Listing } from 'models/ListingModels';
import { convertToWebpAndCompress, uploadToS3WithPresignedUrl } from 'util/imageProcessing';
import { originalToVariants } from 'util/images';

export const useCreateDraftFromProduct = () => {
  const { data: filterOptions } = useGetProductFilterOptions();
  const { createListing } = useCreateListing();
  const { data: brandData } = useGetBrandIdOptions();

  return useMutation({
    mutationFn: async (product: any) => {
      try {
        const imagesToUpload = product.photos.map((photoUrl: string) => ({
          originalUrl: photoUrl,
          imageId: uuidv4(),
        }));

        const processedImages = await Promise.all(
          imagesToUpload.map(async image => {
            const response = await fetch(image.originalUrl);
            if (!response.ok) {
              console.error(`Failed to fetch image from URL: ${image.originalUrl}`);
              return null;
            }
            const blob = await response.blob();

            const file = new File([blob], 'photo.jpg', { type: blob.type });

            const processedFile = await convertToWebpAndCompress(file);

            return {
              ...image,
              processedFile,
            };
          })
        );

        const presignedUrlsResponse = await getPresignedUrl(
          processedImages.map(image => ({
            fileName: `${image.imageId}.webp`,
            imageType: ImageType.Listing,
            fileType: 'image/webp',
          }))
        );

        const uploadPromises = processedImages.map(async (image, index) => {
          const presignedUrl = presignedUrlsResponse[index].presignedUrl;
          await uploadToS3WithPresignedUrl(presignedUrl, image.processedFile);
          const imageUrl = presignedUrl.split('?')[0];
          const variants = originalToVariants(imageUrl);

          return {
            id: image.imageId,
            imageId: image.imageId,
            url: imageUrl,
            variants,
          };
        });

        const uploadedImages = await Promise.all(uploadPromises);

        const uploadedPhotoIds = uploadedImages.map(img => img.imageId);

        // HACK: This is so we don't set partnerId for Shrimps listings, but are still able to  use their DB for listing
        const partnerId =
          product.brandName === 'Shrimps'
            ? undefined
            : String(brandData?.brands?.find(b => b.id === product.brandId)?.partnerId);

        const extractFilterOption = (filterName: string, optionId: string) => {
          return filterOptions
            ?.find(f => f.filterName === filterName)
            ?.options?.find(o => o.id === optionId)?.name;
        };

        const listing: Partial<Listing> = {
          title: product.title || '',
          description: product.description || '',
          ...(Number(product.originalPrice || 0)
            ? {
                priceWhenBought: Number(product.originalPrice || 0) * 100,
                priceWhenBoughtCurrency: 'GBP',
              }
            : {}),
          images: uploadedImages.map(img => img.imageId),
          originalPhotoIds: uploadedPhotoIds,
          brandId: Number(product.brandId),
          department: extractFilterOption('departmentId', product.departmentId),
          category: extractFilterOption('categoryId', product.categoryId),
          productType: extractFilterOption('productTypeId', product.productTypeId),
          productSubtype: extractFilterOption('productSubtypeId', product.productSubtypeId),
          colors: [extractFilterOption('colorId', product.colorId)].filter(Boolean),
          ...(partnerId ? { partnerId: Number(partnerId) } : {}),
        };

        const createdListing = await createListing(listing);

        return createdListing;
      } catch (error: any) {
        console.error('Failed to create draft from product:', error);
        toast.error(error.message || 'Failed to create draft from product');
        throw error;
      }
    },
    meta: { name: 'createDraftFromProduct' },
  });
};
