import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useSendEvent } from '../misc/useSendEvent';
import useCreateSharetribeListing from './sharetribe/useCreateSharetribeListing';
import { Listing } from 'models/ListingModels';
import { createListingSchema } from 'models/ListingSchemas';
import { mapListingResponseToListing } from 'models/mappers/ListingMapper';
import { AnalyticsEvent } from 'util/analytics-events';
import { post } from 'util/httpsClient';
import { omitUndefined } from 'util/httpUtils';

export const useCreateListing = () => {
  const { mutate: sendEvent } = useSendEvent();
  const { createListing: createSharetribeListing } = useCreateSharetribeListing();

  const { mutateAsync: createListingDomain } = useMutation({
    mutationFn: async (listingData: any) => {
      const validatedData = createListingSchema.parse(listingData);
      return post({ path: '/listings', body: validatedData });
    },
  });

  const createListing = useCallback(
    async (listing: Partial<Listing>) => {
      try {
        const createListingRequest = omitUndefined({
          publicData: {
            brandId: listing.brandId?.toString(),
            originalPhotoIds: listing.originalPhotoIds || [],
            removedBgPhotoIds: listing.removedBgPhotoIds || [],
            photoIds: listing.images || [],
            description: listing.description || '',
            partnerId: listing.partnerId || '',
            priceWhenBought: listing.priceWhenBought || '',
            priceWhenBoughtCurrency: listing.priceWhenBoughtCurrency || '',
            department: listing.department || '',
            category: listing.category || '',
            productType: listing.productType || '',
            productSubtype: listing.productSubtype || '',
            colors: listing.colors || [],
          },
          title: listing.title || ' ',
        });

        const sharetribeListingResponse = await createSharetribeListing(createListingRequest);

        const sharetribeListing = sharetribeListingResponse?.data?.data;

        const listingId = sharetribeListing?.id?.uuid;
        if (listingId) {
          sendEvent({
            event: AnalyticsEvent.CreateListing,
            params: { listingId },
          });
        }

        listing.id = listingId;
        const listingResponse = await createListingDomain(listing);

        return mapListingResponseToListing(listingResponse);
      } catch (error) {
        console.error('Error creating listing:', error);
        throw error;
      }
    },
    [createSharetribeListing, createListingDomain, sendEvent]
  );

  return { createListing };
};
