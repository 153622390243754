import { z } from 'zod';

export const createListingSchema = z.object({
  id: z.string(),
  brandId: z.number(),
  images: z.array(z.string()).nonempty(),
  removedBgPhotoIds: z.array(z.string()).optional(),
  originalPhotoIds: z.array(z.string()).optional(),
  partnerId: z.number().optional(),
  title: z.string().optional(),
  description: z.string().optional(),
  priceWhenBought: z.number().optional(),
  priceWhenBoughtCurrency: z.string().optional(),
  colors: z.array(z.string()).optional(),
  productType: z.string().optional(),
  productSubtype: z.string().optional(),
  department: z.string().optional(),
  category: z.string().optional(),
});

export const updateListingSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
  price: z.number().optional(),
  currency: z.string().optional(),
  brandId: z.number().optional(),
  department: z.string().optional(),
  category: z.string().optional(),
  productType: z.string().optional(),
  productSubtype: z.string().optional(),
  priceWhenBought: z.number().optional(),
  priceWhenBoughtCurrency: z.string().optional(),
  condition: z.string().optional(),
  conditionDetails: z.string().optional(),
  colors: z.array(z.string()).optional(),
  size: z.string().optional(),
  sizeStandard: z.string().optional(),
  normalizedSizes: z.array(z.string()).optional(),
  shipsTo: z.array(z.string()).optional(),
  shippingFromAddressId: z.number().optional(),
  images: z.array(z.string()).optional(),
  removedBgPhotoIds: z.array(z.string()).optional(),
  originalPhotoIds: z.array(z.string()).optional(),
  partnerId: z.number().optional(),
});

export interface UpdateListingRequest extends z.infer<typeof updateListingSchema> {
  id: string;
}
